import React, { useRef } from 'react'
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import { Link } from 'react-router-dom';
import {
  Header,
} from 'semantic-ui-react'
import styles from '../../Components.module.css'




export default function ParallaxClickmobile() {
  const parallax = useRef(null); // Using useRef instead of string ref

  return (  
      <div style={{ padding: '0em', width: '100%', height: '100%'}}>
            
        
        {/* --------------------------------------------------------------------------------------------------------- */}
        <Parallax ref={parallax} pages={4} style={{padding: '0em'}}>

        <ParallaxLayer offset={0} speed={0} style={{ backgroundColor: 'transparent' }} />
        
        <ParallaxLayer offset={0} speed={1}>
          <img src="/img/earthP/BLUE.svg" alt="Layer 1" style={{ width: '200%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -80%)' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={0} speed={1}>
          <img src="/img/earthP/WILHEMINA.svg" alt="Layer 2" style={{ width: '200%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -40%)' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={0} speed={1.2}>
          <img src="/img/earthP/ZWAAN.svg" alt="Layer 3" style={{ width: '200%', float: 'left', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-63%, -40%)' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={0} speed={1.4}>
          <img src="/img/earthP/CONSTRUCTION.svg" alt="Layer 4" style={{ width: '170%', float: 'left', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-55%, -30%)'}} />
        </ParallaxLayer>

        <ParallaxLayer offset={0} speed={-0.19}>
          <img src="/img/earthP/STADSGROND.svg" alt="LOGO" style={{ width: '150%', margin: '0 auto', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -65%)'}} />
        </ParallaxLayer>

        <ParallaxLayer offset={0} speed={1.27}>
          <img src="/img/earthP/EARTH.svg" alt="EARTH" style={{ width: '200%', float: 'left', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -40%)'}} />
        </ParallaxLayer>
        
        <ParallaxLayer offset={1} speed={1.3}>
          <img src="/img/earthP/WHITEBG.svg" alt="WHITE" style={{ width: '200%', float: 'left', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, 0%)'}} />
        </ParallaxLayer>


{/* --------------------------------------------------------------------------------------------------------- */}


        <ParallaxLayer
          offset={2.5}
          speed={-0.4}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            pointerEvents: 'none',
          }}>
          
        </ParallaxLayer>

        {/* ----------------------------------PAGE 1 WITH INTERACTION NO IMAGES NEEDED-------------------------------------------- */}
        <ParallaxLayer offset={0} speed={0.01} onClick={() => 
            parallax.current.scrollTo(0.5)}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
        </ParallaxLayer>

       {/* ----------------------------------PAGE 2 WITH INTERACTION-------------------------------------------- */}
        <ParallaxLayer
          offset={1}
          speed={0.1}
          onClick={() => parallax.current.scrollTo(1.8)}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
          }}>
          <img src="img/earthP/AARDEVRIJ.svg" style={{ display: 'block', width: '200%', paddingLeft: '4em', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-30%, -180%)' }} />

        </ParallaxLayer>

        {/* test */}
        <ParallaxLayer
          offset={1}
          speed={0.5}
          onClick={() => parallax.current.scrollTo(1)}
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingRight: '10%'
          }}>
        <h1 style={{marginleft: '70%', transform: 'translateY(-55%)'}} className={styles.headerstylemobile}> Elk jaar komen er miljoenen <br/> kuubs aarde vrij </h1>
        </ParallaxLayer>

       {/* ----------------------------------PAGE 3 WITH INTERACTION-------------------------------------------- */}
        <ParallaxLayer
          offset={2}
          speed={0.1}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => parallax.current.scrollTo(2)}>
          <img src="img/earthP/STORTEN.svg" style={{ width: '200%' , position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -175%)'}}/>
        </ParallaxLayer>

        {/*  */}
        <ParallaxLayer
          offset={1.2}
          speed={0.4}
          onClick={() => parallax.current.scrollTo(2)}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'right',
          }}>
          <h1 style={{ marginRight: '20%', translate: 'translateY(-105%)' }} className={styles.headerstylemobile}> en worden in oude klei putten gestort </h1>
        </ParallaxLayer>


      
       {/* ----------------------------------PAGE 4 WITH INTERACTION-------------------------------------------- */}
       <ParallaxLayer
          offset={2.8}
          speed={0.1}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => parallax.current.scrollTo(3)}>
          <img src="img/earthP/PRODUCEREN.svg" style={{ width: '150%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -235%)' }}/>
        </ParallaxLayer>

        {/*  */}
        <ParallaxLayer
          offset={2.0}
          speed={0.3}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => parallax.current.scrollTo(3)}>
          <h1 style={{ marginleft: '70%',  translate: 'translateY(-200%)' }}  className={styles.headerstylemobile}> Maar waarom maken we er geen stenen van  </h1>
        </ParallaxLayer>

               {/* ----------------------------------PAGE 5 WITH INTERACTION-------------------------------------------- */}
       <ParallaxLayer
          offset={2.8}
          speed={0.1}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => parallax.current.scrollTo(4)}>
          <img src="img/earthP/TOEKOMST.svg" style={{ width: '100%' }}/>
        </ParallaxLayer>

        {/*  */}
                {/*  */}
                <ParallaxLayer
          offset={2.6}
          speed={0.1}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => parallax.current.scrollTo(4)}>
          <h1 style={{ marginleft: '70%'}}  className={styles.headerstylemobile}> En bouwen we samen aan de toekomst  </h1>
        </ParallaxLayer>


                       {/* ----------------------------------PAGE 5 WITH INTERACTION-------------------------------------------- */}
       <ParallaxLayer
          offset={3.2}
          speed={-0}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() =>window.location.href = '/' }>
            
            <img src="img/logo/stadsgrond_logo.svg" style={{ width: '50%' }}/>
            <Header as='h2' style={{fontSize: '3.5em', fontWeight: 'bold'}}>
                            <Link to='/home' style={{marginBottom: '0em', fontFamily: 'Poppins', color: "#b3ccd0"}}>Doe je mee?</Link>
                        </Header>

        </ParallaxLayer>

        
                       {/* ----------------------------------PAGE 6 WITH INTERACTION-------------------------------------------- */}




{/* ---------------------------------------- I AM STICKY sticke werkt nog niet omdat die niet mee gaat met click -------------------------------------------------- */}
{/* <ParallaxLayer sticky={{ start: 3, end: 3 }} style={{ justifyContent: 'flex-start' }} onClick={() => parallax.current.scrollTo(0)}>
      
            <landingpages.sticky />
        </ParallaxLayer> */}

{/* ---------------------------------------- I AM STICKY -------------------------------------------------- */}



      </Parallax>
    </div>
  )
}
