/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import React from 'react'
import 'semantic-ui-css/semantic.min.css';
import Footer from '../../components/Footer'
import ResponsiveContainer from "../../components/ResponsiveContainer";
// import UnderConstruction from "../../components/UnderConstruction";
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';
import {
    Grid,
    Header,
    Image,
    Segment,
    Card,

} from 'semantic-ui-react'
import styles from '../../components/Components.module.css'
import teamMembersData from './team.json'; // Import the JSON file


// layout constants of the team cards
const columnPadding = { paddingBottom: '1em', paddingTop: '1em', textAlign: 'left'};


const Team = () => (
    <ResponsiveContainer>

    <Segment vertical style={{ position: 'relative', height: '100vh', overflow: 'hidden', padding: '0em'}}>
        <ParallaxProvider>
            <ParallaxBanner
            layers={[
                { image: 'img/header/circulairblock.jpg', speed: 15 },
                {
                    speed: -1,
                    children: (
                    <div>
                        {/* <h1 className={styles.lineargradient}>Hello World!</h1> */}
                        {/* old class with h1 which works - now new class with image */}
                        <Image src='img/earthP/STADSGROND2.svg' className={styles.centered} style={{transform: 'translate(0px, 15vh)'}}/>
                    </div>
                    ),
                },
                    
            ]}
            style={{ height: '100%', width: '100%' }}
            className={styles.centered} />

        </ParallaxProvider>
        </Segment>
        
        <Segment vertical > 
        <Grid centered > 
        <Grid.Row style={{ padding: '2em', textAlign: 'center', alignContent: 'center'}}>
                <Header as='h3' style={{fontSize: '3em'}}>
                            
                            <span className={styles.lineargradient} >Team en partners</span>
                        </Header>
                </Grid.Row>
        
        </Grid></Segment>



        <Segment style={{ padding: '0em', backgroundColor: 'var(--bggrey)' }} vertical>
            <Grid vertical columns={4} stackable style={{paddingLeft: '2em', paddingRight: '2em'}}>

                <Grid.Row textAlign='center'>
                {teamMembersData.map(member => (
                    <Grid.Column key={member.id} style={columnPadding}>
                    <Card stackable fluid className='logo'>
                        <div style={{ height: '200px', width: '100%', overflow: 'hidden', padding: '1em'}}>
                            <Image src={member.imageSrc} alt={member.altText} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                        </div>
                        <Card.Content class={member.className}>
                        <Card.Header style={{paddingLeft: '1em'}}>{member.name}</Card.Header>
                        <Card.Meta style={{paddingLeft: '1em'}}>{member.role}</Card.Meta>
                        <Card.Description style={{paddingLeft: '1em'}}>
                            <i className='industry icon' /> {member.description} <br/>
                            <i className='map icon' /> {member.location} <br/>
                            <i className='envelope icon'/><a href={"mailto:" + member.email}>{member.email}</a> <br/>
                            <i className='globe icon'/><a href={member.website} target="_blank">{member.website}</a><br/>
                            <i className='linkedin icon'/><a href={member.linkedin} target="_blank">{member.name}</a>
                        </Card.Description>
                        </Card.Content>
                    </Card>
                    </Grid.Column>
                ))}
                </Grid.Row>
            </Grid>
        </Segment>

 
        {/* <UnderConstruction/> */}
        <Footer/>
    </ResponsiveContainer>
)

export default Team
