import { createMedia } from '@artsy/fresnel'
import PropTypes from 'prop-types'
import React from 'react'
import 'semantic-ui-css/semantic.min.css';
import DesktopContainerWrapper from 'components/Desktop/DesktopContainerWrapper';
import MobileContainerWrapper from 'components/Mobile/MobileContainerWrapper'

const { Media, MediaContextProvider } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})


const ResponsiveContainer = ({ children }) => (
    <MediaContextProvider>
        <Media at='mobile'>
            <MobileContainerWrapper>{children}</MobileContainerWrapper>
        </Media>
        <Media greaterThanOrEqual='tablet'>
            <DesktopContainerWrapper>{children}</DesktopContainerWrapper>
        </Media>
    </MediaContextProvider>
)

ResponsiveContainer.propTypes = {
    children: PropTypes.node,
}

export default ResponsiveContainer