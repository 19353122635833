/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import React from 'react'
import 'semantic-ui-css/semantic.min.css';
import Footer from '../../components/Footer'
import ResponsiveContainer from "../../components/ResponsiveContainer";

import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';
import {
    Image,
    Segment, 
    Grid
} from 'semantic-ui-react'
import styles from '../../components/Components.module.css'


const Products = () => (
    <ResponsiveContainer>



        {/* SEGMENT 1 PHOTO */}
        <Segment vertical style={{ position: 'relative', height: '90vh', overflow: 'hidden', padding: '0em', border: 'none'}}>
      <ParallaxProvider>
        <ParallaxBanner
          layers={[
            { image: 'img/header/StudioActe.jpg', speed: 15},
            {
                speed: -1,
               
                children: (
                  <div className={styles.imageWrapper}>
                    {/* <h1 className={styles.lineargradient}>Hello World!</h1> */}
                    {/* old class with h1 which works - now new class with image */}
                    <Image centered src='img/earthP/STADSGROND_TEXT.svg' style={{transform: 'translate(0px, 15vh)', maxWidth: '30vw', minWidth: '250px'}}/>
  
                  </div>
                ),
              },
                   
        ]}
          style={{ height: '100%', width: '100%' }}
          className={styles.centered} />

      </ParallaxProvider>
    </Segment>



    <Segment vertical style={{ position: 'relative', overflow: 'hidden', padding: '0em'}}>
        <ParallaxProvider>
            <ParallaxBanner
            layers={[
                { image: 'img/header/StudioActe.jpg', speed: 15 },
                {
                    speed: -1,
                    children: (
                    <div>
                        <Image src='img/earthP/STADSGROND2.svg' className={styles.centered} style={{transform: 'translate(0px, 15vh)'}}/>
                    </div>
                    ),
                },
                    
            ]} />

        </ParallaxProvider>
        </Segment>
        


{/* part 4 klei  */}
<Segment vertical style={{ position: 'relative', overflow: 'hidden', padding: '2em', border: '0px'}}>
      <Grid centered>
         <Grid.Column width={8}>        
            <p className={styles.h02}> We werken momenteel hard aan de productie in Rotterdam. In de tussentijd kunt u terecht bij   <a href='https://www.oskam-vf.com' style={{color: 'black', textDecoration:'underline'}} target="_blank"> www.oskam-vf.com</a> voor Nederlandse Leemproducten. </p>          
         </Grid.Column>

      </Grid>
 </Segment>


{/*  
        <UnderConstruction/> */}
        <Footer/>
    </ResponsiveContainer>
)

export default Products
