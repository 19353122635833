import React, { Component } from 'react'
import { createMedia } from '@artsy/fresnel'
import PropTypes from 'prop-types'
import 'semantic-ui-css/semantic.min.css';
import {
  Icon,
  Image,
  Menu,
  Segment,
  Sidebar,
} from 'semantic-ui-react'
import { Link, NavLink } from 'react-router-dom';
import ContactModal from '../Contact'

const { Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
})

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children, location } = this.props
    const { sidebarOpened } = this.state

    const isActive = (path) => location.pathname === path;
    return (
      <Media as={Sidebar.Pushable} at='mobile'>
        <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            animation='overlay'
            onHide={this.handleSidebarHide}
            vertical
            visible={sidebarOpened}
            style = {{ background: 'var(--light-grey)'}}
          >
            <Menu.Item as={Link} to='/' active={isActive('/')}>Home</Menu.Item>
            <Menu.Item as={NavLink} to='/concept' active={isActive('/concept')}>
                  concept
                </Menu.Item>
                <Menu.Item as={NavLink} to='/products' active={isActive('/products')}>
                  producten
                </Menu.Item>
                {/* <Menu.Item as={NavLink} to='/advies' active={isActive('/advies')}>
                  advies
                </Menu.Item>
                <Menu.Item as={NavLink} to='/bouw' active={isActive('/bouw')}>
                  bouw
                </Menu.Item> */}
                <Menu.Item as={NavLink} to='/lab' active={isActive('/lab')}>
                  aarde lab
                </Menu.Item>
                <Menu.Item as={NavLink} to='/team' active={isActive('/team')}>
                  team
                </Menu.Item>
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              textAlign='center'
              style={{ backgroundColor: 'var(--light-grey)', padding: '0em 0em'}}
              vertical
            >
                <Menu pointing secondary size='large'>
                  <Menu.Item onClick={this.handleToggle} style={{paddingBottom:'1.5em'}}>
                    <Icon name='sidebar' />
                  </Menu.Item>
                  <Menu.Item as={Link} to='/' >
                    <Image src='img/logo/logo_crv.svg' style={{height: '2.5rem'}}/>
                  </Menu.Item>
                  <Menu.Item position='right'>
                    <ContactModal />
                  </Menu.Item>
                </Menu>
            </Segment>
            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Media>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}


export default MobileContainer
