import 'semantic-ui-css/semantic.min.css';
import {
  Container,
  Grid,
  Header,
  Image,
  Segment,
} from 'semantic-ui-react'

const Footer = () => {
return (
<Segment vertical style={{ backgroundColor: 'var(--bggrey)', padding: '5em 0em'}}>
  <Container>
    <Grid stackable textAlign='center'>
      <Grid.Row>

        {/* <Grid.Column width={3}>
          <Header inverted as='h4' content='Informatie' />
          <List link inverted>
            <List.Item as={Link} to='/' name=''>Over</List.Item>
            <List.Item as={Link} to='/' name=''>Contact</List.Item>
            <List.Item as={Link} to='/' name=''>Technologie</List.Item>
            <List.Item as={Link} to='/' name=''>Roadmap</List.Item>
            </Grid.Column>
</List>*/}

        <Grid.Column width={3}>
          <Header as='h4' >
            Contact Stadsgrond
          </Header>
          <p> email:    <a href='mailto:georges@stadsgrond.com' style={{color: 'black', textDecoration:'underline'}}>georges@stadsgrond.com</a> </p>
          <p> telefoon: +316 18 99 15 18</p>
          <p> Schiemond 20</p>
        </Grid.Column>
        <Grid.Column width={8}>
          <Image src='img/logo/logo_crv.svg' style={{ display: 'inline-block', minHeight: '100px', minWidth: '100px', maxHeight: '100px'}}/>
        </Grid.Column>
        <Grid.Column width={3}>
          <Header as='h4' >
            Contact Oskam V/F
          </Header>
          <p> email:    <a href='mailto:info@oskam-vf.com' style={{color: 'black', textDecoration:'underline'}}>info@oskam-vf.com</a> </p>
          <p> telefoon: 0180-663998 </p>
          <p> <a href= 'https://www.oskam-vf.com' style={{color: 'black', textDecoration:'underline'}} target="_blank" > www.oskam-vf.com </a></p>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
</Segment>
);
}

export default Footer
