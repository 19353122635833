import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createMedia } from '@artsy/fresnel'
import { Segment, Menu, Container, Image } from 'semantic-ui-react';
import { Link, NavLink } from 'react-router-dom';
import ContactModal from './../Contact';
import { InView } from 'react-intersection-observer'; // Ensure you have this for the InView component


const { Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
})

class DesktopContainer extends Component {
  state = {}

  toggleFixedMenu = (inView) => this.setState({ fixed: !inView })
  render() {
    const { children, location } = this.props;
    const { fixed } = this.state;

    // Determine if the current menu item is active based on the location
    const isActive = (path) => location.pathname === path;

    return (
      <Media greaterThan='mobile'>
        <InView onChange={this.toggleFixedMenu}>
          <Segment textAlign='center' style={{ backgroundColor: `var(--light-grey)`, padding: '0em' }} vertical>
            <Menu
              fixed={location.pathname ==='/' ? (fixed ? null : null) : 'top'}
              borderless
              size='huge'             
              
              style={{ backgroundColor: 'var(--light-grey)', padding: '0em', color: '#895c5c'}}
            >
              <Container>
                <Menu.Item as={Link} to='/' active={isActive('/')}>
                  <Image src='img/logo/logo_crv.svg' style={{ height: '2rem' }} />
                </Menu.Item>
                <Menu.Item as={NavLink} to='/concept' active={isActive('/concept')}>
                  concept
                </Menu.Item>
                <Menu.Item as={NavLink} to='/products' active={isActive('/products')}>
                  producten
                </Menu.Item>
                {/* <Menu.Item as={NavLink} to='/advies' active={isActive('/advies')}>
                  advies
                </Menu.Item>
                <Menu.Item as={NavLink} to='/bouw' active={isActive('/bouw')}>
                  bouw
                </Menu.Item> */}
                <Menu.Item as={NavLink} to='/lab' active={isActive('/lab')}>
                  aarde lab
                </Menu.Item>
                <Menu.Item as={NavLink} to='/team' active={isActive('/team')}>
                  team
                </Menu.Item>
                <Menu.Item position='right'>
                  <ContactModal />
                </Menu.Item>
              </Container>
            </Menu>
          </Segment>
        </InView>
        {children}
      </Media>
    );
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object.isRequired, // Ensure location is marked as required
}

// Note: Now, you should use DesktopContainerWithRouter instead of DesktopContainer directly in your app
export default DesktopContainer
