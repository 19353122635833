/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import React, { useState, useEffect } from 'react'
import 'semantic-ui-css/semantic.min.css';
import ResponsiveContainer from "../../components/ResponsiveContainer";
import ParallaxClick from 'components/Parallax/Parallax_withclick/ParallaxClick';
import {
    Grid,
    Segment,
  } from 'semantic-ui-react'
import ParallaxClickmobile from 'components/Parallax/Parallax_withclick/ParallaxClickmobile';

  

const Concept = () => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const resizeListener = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, []);

    return(
    <ResponsiveContainer>



        {/* <Segment style={{backgroundColor: '#666666'}}>
            <Grid.Row style={{display: 'flex', minHeight: '100vh'}}>

            <Parallax style={{ width: '100%', height: '100%'  }}/>
            
            </Grid.Row>
        </Segment> */}
        <Segment vertical style={{paddingLeft: '0'}}>
            <Grid.Row style={{display: 'flex', minHeight: '100vh'}}>

            { screenWidth > 767 ?
            <ParallaxClick style={{ width: '100%', height: '100%'  }}/> :
            <ParallaxClickmobile  vertical style={{ width: '100%', height: '100%', padding: '0', paddingLeft: '0'}}/>
            }
            </Grid.Row>
        </Segment>

    </ResponsiveContainer>
    );
}

export default Concept
