import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import "./index.css";
import "./fonts/fonts.css"
import reportWebVitals from "./reportWebVitals";
import Products from "pages/products/products";
// import Advies from "pages/advies/advies"; 
import Lab from 'pages/lab/lab';
// import Bouw from 'pages/bouw/bouw';
import Concept from 'pages/concept/concept';
import HomePage from "./pages/home/HomePage";
import Team from "./pages/team/team";
import ScrollToTop from './components/ScrollToTop'


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage/>} />
        <Route path="/concept" element={<Concept />} />
        <Route path="/products" element={<Products />} />
        {/* <Route path="/advies" element={<Advies/>} /> */}
        {/* <Route path="/bouw" element={<Bouw/>} /> */}
        <Route path="/lab" element={<Lab/>} />
        <Route path="/team" element={<Team/>} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
