import React from 'react';
import { useLocation } from 'react-router-dom';
import MobileContainer from './MobileContainer'; // Adjust the import path as necessary

function MobileContainerWrapper(props) {
  const location = useLocation();

  return <MobileContainer {...props} location={location} />;
}

export default MobileContainerWrapper;
