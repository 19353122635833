/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import React from 'react'
import 'semantic-ui-css/semantic.min.css';
import Footer from '../../components/Footer'
import ResponsiveContainer from "../../components/ResponsiveContainer";
import UnderConstruction from "../../components/UnderConstruction";
import { ParallaxBanner, ParallaxProvider } from 'react-scroll-parallax';
import {
    Image,
    Segment,
} from 'semantic-ui-react'
import styles from '../../components/Components.module.css'

const Advies = () => (
    <ResponsiveContainer>

<Segment vertical style={{ position: 'relative', height: '100vh', overflow: 'hidden', padding: '0em'}}>
        <ParallaxProvider>
            <ParallaxBanner
            layers={[
                { image: 'img/header/lab.jpeg', speed: 15 },
                {
                    speed: -1,
                    children: (
                    <div>
                        {/* <h1 className={styles.lineargradient}>Hello World!</h1> */}
                        {/* old class with h1 which works - now new class with image */}
                        <Image src='img/earthP/STADSGROND3.svg' className={styles.centered} style={{transform: 'translate(0px, 15vh)'}}/>
                    </div>
                    ),
                },
                    
            ]}
            style={{ height: '100%', width: '100%' }}
            className={styles.centered} />

        </ParallaxProvider>
        </Segment>

        
        <UnderConstruction/>
        <Footer/>
    </ResponsiveContainer>
)

export default Advies
