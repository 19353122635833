/* eslint-disable max-classes-per-file */
/* eslint-disable react/no-multi-comp */

import React from 'react'
import 'semantic-ui-css/semantic.min.css';
import {
    Grid,
    Image,
    Segment,
} from 'semantic-ui-react'
import styles from '../../components/Components.module.css'
import Footer from '../../components/Footer'
import ResponsiveContainer from "../../components/ResponsiveContainer";

import {ParallaxBanner, ParallaxProvider} from "react-scroll-parallax";
import '../../fonts/fonts.css'
// import ZoomImg from 'components/Parallax_horizontal/zoomimg';


const HomePage = () => (
    <ResponsiveContainer>

        {/* SEGMENT 1 PHOTO */}
        <Segment vertical style={{ position: 'relative', height: '90vh', overflow: 'hidden', padding: '0em', border: 'none'}}>
      <ParallaxProvider>
        <ParallaxBanner
          layers={[
            { image: 'img/earthproducts/earth_samples_full.jpg', speed: 30, translateY: 12},
            {
                speed: -1,
               
                children: (
                  <div className={styles.imageWrapper}>
                    {/* <h1 className={styles.lineargradient}>Hello World!</h1> */}
                    {/* old class with h1 which works - now new class with image */}
                    <Image centered src='img/earthP/STADSGROND_TEXT.svg' style={{transform: 'translate(0px, 15vh)', maxWidth: '30vw', minWidth: '250px'}}/>
  
                  </div>
                ),
              },
                   
        ]}
          style={{ height: '100%', width: '100%' }}
          className={styles.centered} />

      </ParallaxProvider>
    </Segment>
{/* part 2 missie  */}
    <Segment vertical style={{ position: 'relative', overflow: 'hidden', padding: '3em', border: '0px' }}>
      <Grid centered>
         <Grid.Column width={12}>        
            <p className={styles.h02}> Stadsgrond biedt een oplossing voor de bouw door een grote reststroom klei in te zetten als duurzaam bouwmateriaal. </p>          
         </Grid.Column>

      </Grid>
 </Segment>
   


    {/* segment diagram */}
    <Segment vertical style={{ backgroundColor: 'var(--bggrey)', display: 'flex', flexDirection: 'column', border: '0px'}}>


            <Image src='img/circlediagram.svg' style={{}}/>

</Segment>
{/* part 4 klei  */}
<Segment vertical style={{ position: 'relative', overflow: 'hidden', padding: '2em', border: '0px'}}>
      <Grid centered>
         <Grid.Column width={12}>        
            <p className={styles.h02}> Bouwen met ruwe aarde kent vele voordelen </p>          
         </Grid.Column>

      </Grid>
 </Segment>

<Segment vertical style={{padding:'0em', paddingBottom: '1em', border: '0px'}}>
<Grid columns={4} vertical stackable doubling verticalAlign='top'>
  <Grid.Column> 
  <Image src='img/earthpicto/pico_circulair.svg'/>
  <p className={styles.h02}> 100% circulair </p>
  <p className={styles.p01} style={{paddingLeft: '1em'}}> Aarde bouwmaterialen worden zonder additieven gemaakt, waardoor het na gebruik weer terug kan waar het vandaan komt, de aarde. </p>
  </Grid.Column>

  <Grid.Column>
  <Image src='img/earthpicto/pico_CO2.svg'/>
  <p className={styles.h02}> 0% uitstoot </p>
  <p className={styles.p01} style={{paddingLeft: '1em'}}> Het materiaal stoot 0% C02 uit in de productie.</p>
     </Grid.Column>

  <Grid.Column>
  <Image src='img/earthpicto/pico_gezond.svg'/>  
  <p className={styles.h02}> Gezond </p>
  <p className={styles.p01} style={{paddingLeft: '1em'}}> Aarde gebouwen zorgen voor een aangenaam binnenklimaat en zijn erg gezond voor de bewoners. </p>
       </Grid.Column>

       <Grid.Column>
  <Image src='img/earthpicto/pico_structure.svg'/>    
  <p className={styles.h02}> Constructief </p>
  <p className={styles.p01} style={{paddingLeft: '1em'}}> Aarde constructies zijn sterk, veilig en duurzaam. De Romeinen werkte al met aarde bouwmaterialen vanwege haar sterke eigenschappen. </p>

     </Grid.Column>

  <Grid.Column> 
  <Image src='img/earthpicto/pico_sound.svg'/>
  <p className={styles.h02}> Geluid isolerend </p>
  <p className={styles.p01} style={{paddingLeft: '1em'}}> Gebouwen met een constructie van ongebakken aarde hebben een hoge geluidsisolatie. </p>

  </Grid.Column>



  <Grid.Column> 
  <Image src='img/earthpicto/pico_veilig.svg'/>
  <p className={styles.h02}> Veilig </p>
  <p className={styles.p01} style={{paddingLeft: '1em'}}> Het materiaal is veilig voor de bouwer om mee te werken, waardoor geen extra maatregelen nodig zijn wanneer er mee gewerkt wordt.</p>

  </Grid.Column>

  <Grid.Column>
  <Image src='img/earthpicto/pico_fire.svg'/>  
  <p className={styles.h02}> Vuur resistent </p>
  <p className={styles.p01} style={{paddingLeft: '1em'}}> Aarde constructie zijn resistent voor vuur en vertragen brand.  </p>
  
     </Grid.Column>

  <Grid.Column> 
  <Image src='img/earthpicto/pico_accumuleren.svg'/>
  <p className={styles.h02}> Klimaatbuffer </p>
  <p className={styles.p01} style={{paddingLeft: '1em'}}> Het materiaal kan veel energie vasthouden, waardoor het in de zomer koel is en in de winter warm.  </p>

  </Grid.Column>

  <Grid.Column> 
  <Image src='img/earthpicto/pico_humid.svg'/>
  <p className={styles.h02}> Vochtregulerend </p>
  <p className={styles.p01} style={{paddingLeft: '1em'}}> Wanneer het vochtig is zuigt het materiaal het vocht op, wanneer het droog is geeft die het terug. Hierdoor is het binnen altijd aangenaam. </p>

  </Grid.Column>





     <Grid.Column>
  <Image src='img/earthpicto/pico_plenty.svg'/>    
  <p className={styles.h02}> Oneindig </p>
  <p className={styles.p01} style={{paddingLeft: '1em', paddingBottom: '1em'}}> Aarde is de meest voorradige grondstof die we hebben. Het proces is reversibel waardoor we het oneindig kunnen blijven gebruiken. </p>

     </Grid.Column>

     <Grid.Column>
  <Image src='img/earthpicto/pico_easy.svg'/>    
  <p className={styles.h02}> Makkelijk </p>
  <p className={styles.p01} style={{paddingLeft: '1em', paddingBottom: '1em'}}> Bouwen met aarde is eenvoudig en makkelijk te leren.</p>

     </Grid.Column>

     <Grid.Column>
  <Image src='img/earthpicto/pico_interior.svg'/>    
  <p className={styles.h02}> Afwerking </p>
  <p className={styles.p01} style={{paddingLeft: '1em', paddingBottom: '1em'}}> Naast aarde als constructie is het ook toepasbaar als stuc en als vloer, ideaal voor een mooi interieur.  </p>

     </Grid.Column>
  




</Grid>


</Segment>




        {/* SEGMENT 2 MISSIE */}









        <Footer />
    </ResponsiveContainer>
)

export default HomePage
