import {Grid, Header, Image, Segment} from "semantic-ui-react";
import styles from "./Components.module.css";
import React from "react";
import {createMedia} from "@artsy/fresnel";
import 'semantic-ui-css/semantic.min.css';

// eslint-disable-next-line
const { Media, MediaContextProvider } = createMedia({
    breakpoints: {
        mobile: 0,
        tablet: 768,
        computer: 1024,
    },
})

function UnderConstruction() {

    return (
        <MediaContextProvider>



        <Segment vertical style={{backgroundColor: 'var(--bggrey)', padding: '0em'}}>
            <Grid container stackable verticalAlign='middle' style={{padding: '8em 0em'}}>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Header as='h3' style={{fontSize: '1em'}}>
                            Pagina is nog onder  
                            <span className={styles.lineargradient} > constructie </span>
                        </Header>
                    </Grid.Column>
                    <Grid.Column floated='right' width={6}>
                        <Image rounded size='large' src='img/logo/stadsgrond_logo.svg'/>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    </MediaContextProvider>
);
}

export default UnderConstruction